import cx from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import { IStaticImageProps } from "gatsby-plugin-image/dist/src/components/static-image.server";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useMemo } from "react";

type Props = {
	className?: string;
	children?: React.ReactNode;
	variant?: 1 | 2 | 3;
} & React.HTMLAttributes<HTMLDivElement>;

export const Hero: React.FC<Props> = ({
	children,
	className,
	variant,
	...props
}) => {
	const { language } = useI18next();
	const LargeBg = useMemo(() => {
		const bgProps: Pick<IStaticImageProps, "alt" | "layout" | "className"> = {
			alt: "",
			layout: "fullWidth",
			className: "hidden col-span-full row-span-full lg:block",
		};
		return variant ? (
			[
				<StaticImage src="../images/ttl01_bg.png" {...bgProps} />,
				<StaticImage src="../images/ttl02_bg.png" {...bgProps} />,
				<StaticImage src="../images/ttl03_bg.png" {...bgProps} />,
			][variant - 1]
		) : (
			<StaticImage src="../images/hero-bg.png" {...bgProps} />
		);
	}, [variant]);

	return (
		<div className={cx("grid", className)} {...props}>
			<StaticImage
				src="../images/hero-bg-m.png"
				alt=""
				layout="fullWidth"
				className="col-span-full row-span-full lg:hidden"
			/>
			{LargeBg}
			<div className="z-0 flex items-center justify-center overflow-hidden col-span-full row-span-full">
				<h2
					className={cx(
						"italic transform -rotate-3",
						"[&_strong]:text-[1.25em] [&_strong]:leading-tight",
						"text-shadow-hero text-center",
						language === "en"
							? "leading-normal text-[0.9rem] md:text-3xl xl:text-[2.75rem]"
							: "text-2xl md:text-5xl 2xl:text-6xl"
					)}
				>
					{children}
				</h2>
			</div>
		</div>
	);
};
