import * as React from "react";
import {
	GatsbyImage,
	IGatsbyImageData,
	ImageDataLike,
	getImage,
} from "gatsby-plugin-image";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";

type Props = {
	images: NonNullable<
		NonNullable<Queries.HomeQuery["vision"]>["frontmatter"]
	>["images"];
};

export const WorldMarquee = ({ images }: Props) => {
	return (
		<Swiper
			loop
			centeredSlides
			modules={[Autoplay]}
			spaceBetween={52}
			speed={12000}
			autoplay={{ delay: 1, disableOnInteraction: true }}
			slidesPerView="auto"
			allowTouchMove={false}
			className="w-full max-w-[100vw] [&_.swiper-wrapper]:ease-linear"
		>
			{images?.map((item) => (
				<SwiperSlide
					key={item?.image?.childImageSharp?.id}
					className="max-w-[624px]"
				>
					<GatsbyImage
						image={getImage(item?.image as ImageDataLike) as IGatsbyImageData}
						alt="World Image"
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
};
