import "swiper/css";

import cx from "classnames";
import {
	GatsbyImage,
	getImage,
	IGatsbyImageData,
	ImageDataLike,
	StaticImage
} from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import { ReactComponent as ForwardIcon } from "icons/forward.svg";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import type SwiperClass from "swiper/types/swiper-class";
type Props = {
	features: Queries.HomeQuery["features"];
	weapons: Queries.HomeQuery["weapons"];
};

export const FeatureSection: React.FC<Props> = ({ features, weapons }) => {
	const { language } = useI18next();
	const titleFontSize = {
		"text-lg 2xl:text-xl": language === "en",
		"text-2xl 2xl:text-3xl": language === "ko",
		"text-2xl": language === "ja",
		"text-3xl": ["zh-tw", "zh-cn"].includes(language),
	};
	const summaryFontSize = {
		"text-lg": ["ko", "zh-tw"].includes(language),
	};

	return (
		<div className="grid mt-12 lg:mt-16">
			<StaticImage
				src="../images/feature-bg-m.png"
				alt=""
				layout="fullWidth"
				objectPosition="50% 100%"
				className="lg:hidden col-span-full row-span-full -z-10"
			/>
			<StaticImage
				src="../images/feature-bg.png"
				alt=""
				layout="fullWidth"
				objectPosition="50% 100%"
				className="hidden lg:block col-span-full row-span-full -z-10 2xl:max-h-[110vh]"
			/>
			<div className="col-span-full row-span-full">
				<div className="flex flex-col w-full max-w-[100vw]">
					<section className="mx-auto max-w-[1460px]">
						<Swiper
							cssMode
							slidesPerView="auto"
							breakpoints={{ 1200: { slidesPerView: 4 } }}
						>
							{features.edges.map(({ node }) => (
								<SwiperSlide key={node.id} className="max-w-[365px]">
									<SwiperItem className="grid">
										<GatsbyImage
											alt={node.frontmatter?.title ?? ""}
											image={
												getImage(
													node.frontmatter?.image as ImageDataLike
												) as IGatsbyImageData
											}
											className="col-span-full row-span-full"
										/>
										<div className="z-0 flex flex-col justify-end pb-16 col-span-full row-span-full">
											<div className="max-w-[70%] mx-auto">
												<h3
													className={cx(
														"uppercase text-center leading-normal font-bold break-all",
														titleFontSize
													)}
													dangerouslySetInnerHTML={{
														__html:
															node.frontmatter?.title?.replace("\n", "<br>") ??
															"",
													}}
												/>
												<hr className="my-3 border-none rounded-full h-[2px] bg-[linear-gradient(to_right,#737373,#bcbcbc,#fff,#bcbcbc,#737373)]" />
												<p
													className={cx(
														["zh-tw", "zh-cn"].includes(language)
															? "h-[4.5em] line-clamp-3"
															: "h-[6em] line-clamp-4",
														summaryFontSize
													)}
												>
													{node.frontmatter?.introduction}
												</p>
											</div>
										</div>
									</SwiperItem>
								</SwiperSlide>
							))}
						</Swiper>
					</section>
					<Weapons weapons={weapons} />
				</div>
			</div>
		</div>
	);
};

const SwiperItem = (props: {
	url?: string | null;
	className: string;
	children: React.ReactNode;
}) =>
	!!props.url ? (
		<a href={props.url} rel="noopener noreferrer" target="_blank" {...props} />
	) : (
		<div {...props} />
	);

const Weapons = ({ weapons }: Pick<Props, "weapons">) => {
	const [swiper, setSwiper] = useState<SwiperClass | null>(null);
	const handleNext = () => swiper?.slideNext();
	const handlePrev = () => swiper?.slidePrev();

	return (
		<section className="mt-10 overflow-hidden">
			<div className="relative lg:max-w-screen-md lg:mx-auto 2xl:max-w-screen-lg">
				<Swiper loop slidesPerView={1} onSwiper={setSwiper}>
					{weapons.edges.map(({ node }) => (
						<SwiperSlide key={node.id} className="items-center lg:flex">
							<div className="w-48 mx-auto md:w-80 shrink-0 lg:mr-4">
								<GatsbyImage
									alt={node.frontmatter?.title ?? ""}
									image={
										getImage(
											node.frontmatter?.image as ImageDataLike
										) as IGatsbyImageData
									}
								/>
							</div>
							<div className="px-3 mt-6">
								<div className="flex items-end space-x-1 font-bold">
									<span className="flex flex-wrap items-end text-lg md:text-[22px] leading-none mr-2">
										<img
											loading="lazy"
											src={node.frontmatter?.icon?.publicURL ?? ""}
											alt="Icon"
											className="mr-2.5 w-[27px] h-[27px] md:w-[34px] md:h-[33px]"
										/>
										{node.frontmatter?.general}
										<span className="block w-full h-[3px] bg-[#c2bfde] mt-1.5" />
									</span>
									<h3 className="leading-none text-[30px] md:text-[36px] grow">
										{node.frontmatter?.title}
										<span className="block w-full h-[3px] mt-1.5 bg-gradient-to-r from-[#c2bfde] to-[rgb(57,35,139)]" />
									</h3>
								</div>
								<p className="py-3 text-sm md:text-lg">
									{node.frontmatter?.introduction}
								</p>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
				<Control
					inverse
					onClick={handlePrev}
					className="left-3 md:-left-3 lg:-left-[247px]"
				/>
				<Control
					onClick={handleNext}
					className="right-3 md:-right-3 lg:-right-[247px]"
				/>
			</div>
		</section>
	);
};

// noinspection AllyPlainJsInspection
const shadowAttributes = {
	alt: "",
	width: 223,
	height: 106,
	className: "col-span-full row-span-full !hidden md:!inline-block",
};
const Control = (props: {
	onClick: () => void;
	inverse?: boolean;
	className?: string;
}) => {
	return (
		<button
			type="button"
			onClick={props.onClick}
			className={cx(
				"absolute top-20 md:top-28 z-10 md:grid md:items-center",
				{ "md:justify-items-end": props.inverse },
				props.className
			)}
		>
			{props.inverse ? (
				<StaticImage
					src="../images/control-shadow-prev.png"
					{...shadowAttributes}
				/>
			) : (
				<StaticImage
					src="../images/control-shadow-next.png"
					{...shadowAttributes}
				/>
			)}
			<span
				className={cx(
					"col-span-full row-span-full z-0",
					props.inverse ? "md:pr-5" : "md:pl-5"
				)}
			>
				<ForwardIcon
					className={cx("w-[53px] h-[34px]", {
						"transform rotate-180": props.inverse,
					})}
				/>
			</span>
		</button>
	);
};
