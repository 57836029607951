import { Logo } from "components/Logo";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Links } from "libs/constants";
import React, { Suspense, useMemo } from "react";

import { useMediaQuery, useSessionStorageValue } from "@react-hookz/web";

const Video = React.lazy(() =>
	import("components/KVPlayer").then((mod) => ({
		default: mod.KVPlayer,
	}))
);

export const Billboard = () => {
	const { t, language } = useI18next();
	const isDesktop = useMediaQuery("(min-width: 1024px)");
	const [confirmed] = useSessionStorageValue<boolean>("age_confirmed", false);
	const image = useMemo<Record<string, any>>(
		() => ({
			"zh-tw": <StaticImage src="../images/kv-ttl-zh-tw.png" alt="" />,
			"zh-cn": <StaticImage src="../images/kv-ttl-zh-cn.png" alt="" />,
			en: <StaticImage src="../images/kv-ttl-en.png" alt="" />,
			ja: <StaticImage src="../images/kv-ttl-ja.png" alt="" />,
			ko: <StaticImage src="../images/kv-ttl-ko.png" alt="" />,
		}),
		[]
	);

	return (
		<div id="billboard" className="relative grid">
			<StaticImage
				alt=""
				src="../images/kv-bg.jpg"
				layout="fullWidth"
				className="hidden col-span-full row-span-full md:inline-block"
			/>
			<StaticImage
				alt=""
				src="../images/kv-bg-m.png"
				layout="fullWidth"
				className="col-span-full row-span-full md:hidden"
			/>
			<div className="z-0 col-span-full row-span-full transition-opacity [&_video]:object-cover">
				<Suspense fallback={<div />}>
					<Video
						playing={confirmed}
						url={isDesktop ? "/kv-video.mp4" : "/kv-video-m.mp4"}
					/>
				</Suspense>
			</div>
			<section className="z-10 pt-16 text-center col-span-full row-span-full place-items-center md:pt-10">
				<div className="max-w-screen-lg mx-auto">
					<StaticImage
						loading="eager"
						src="../images/kv-char.png"
						alt=""
						className="-mb-[20%] md:-mb-[25%]"
						placeholder="blurred"
					/>
				</div>
				<div className="mx-auto max-w-[369px] md:max-w-[1008px]">
					{image[language]}
				</div>
				<h1 className="sr-only">{t("slogan")}</h1>
				<a href={language === 'en' ? Links.DownloadEn : Links.Download} rel="noopener noreferrer" target="_blank">
					<StaticImage
						src="../images/download-icon.png"
						alt="Play on Apple and Android Now."
						className="w-52 lg:w-60"
						placeholder="blurred"
						sizes="240px"
					/>
				</a>
			</section>
			<Logo positioned />
		</div>
	);
};
