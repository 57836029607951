import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Links } from "libs/constants";
import React from "react";

import { CtaButton } from "./CtaButton";
import { NewsList, Props } from "./NewsList";
import { SectionHeading } from "./SectionHeading";

export const NewsSection: React.FC<Props> = ({ items }) => {
	const { t } = useTranslation();

	return (
		<div className="grid md:-mt-12">
			<StaticImage
				src="../images/reward-bg-m.png"
				alt=""
				layout="fullWidth"
				objectPosition="50% 0%"
				className="col-span-full row-span-full lg:hidden -z-10"
			/>
			<StaticImage
				src="../images/reward-bg.jpg"
				alt=""
				layout="fullWidth"
				objectPosition="50% 0"
				className="hidden max-h-screen col-span-full row-span-full lg:block -z-10"
			/>
			<section className="flex flex-col items-center pt-7 lg:pt-12 col-span-full row-span-full">
				<SectionHeading className="justify-center w-full mb-12">
					{t("title.news")}
				</SectionHeading>
				<NewsList items={items}>
					<div className="mt-8 text-center">
						<CtaButton to={Links.Announcements}>{t("button.more")}</CtaButton>
					</div>
				</NewsList>
			</section>
		</div>
	);
};
