import "swiper/css";

import { CtaButton } from "components/CtaButton";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Links } from "libs/constants";
import React, { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { useMediaQuery } from "@react-hookz/web";

export const CharacterSection = () => {
	const { t } = useTranslation();
	const isDesktop = useMediaQuery("(min-width: 1024px)");
	const Assets = useMemo(
		() => [
			{
				logo: (
					<StaticImage
						src="../images/logo-aura.png"
						alt="Logo"
						className="w-10 h-10 md:w-16 md:h-16 mr-1.5"
					/>
				),
				title: t("cadette.title"),
				introduction: t("cadette.introduction"),
				bg: <StaticImage src="../images/character-1.png" alt="Avatar" />,
			},
			{
				logo: (
					<StaticImage
						src="../images/logo-chaos.png"
						alt="Logo"
						className="w-[35px] h-[34px] md:w-16 md:h-16 mr-1.5"
					/>
				),
				title: t("mimic.title"),
				introduction: t("mimic.introduction"),
				bg: <StaticImage src="../images/character-2.png" alt="Avatar" />,
			},
		],
		[t]
	);
	const CommentBg = useMemo(
		() =>
			isDesktop ? (
				<StaticImage
					src="../images/box-character-comment.png"
					alt=""
					layout="fullWidth"
					className="col-span-full row-span-full -z-10"
				/>
			) : (
				<StaticImage
					src="../images/box-character-comment-m.png"
					alt=""
					layout="fullWidth"
					className="col-span-full row-span-full -z-10"
				/>
			),
		[isDesktop]
	);

	return (
		<div className="my-10 lg:grid">
			<StaticImage
				src="../images/character-section-bg.png"
				alt=""
				className="hidden lg:block col-span-full row-span-full -z-10 2xl:max-h-screen"
				layout="fullWidth"
			/>
			<div className="col-span-full row-span-full lg:flex lg:items-end">
				<Swiper
					cssMode
					slidesPerView="auto"
					breakpoints={{ 768: { slidesPerView: 2 } }}
					className="max-w-[1074px] mx-auto"
				>
					{Array.from({ length: 2 }).map((_, i) => (
						<SwiperSlide key={i} className="grid max-w-[537px]  group">
							<div className="col-span-full row-span-full -z-20 lg:hidden">
								{Assets[i].bg}
							</div>
							<div className="flex flex-col items-center justify-end col-span-full row-span-full pt-52 md:pt-80 lg:pt-0">
								<h3
									className="
									font-bold leading-none text-[34px] lg:text-[57px] italic flex items-center
									group-first:text-white group-first:drop-shadow-[0_0_12px_rgba(0,0,0,0.75)]
									md:group-first:drop-shadow-[0_12px_8px_rgba(0,0,0,0.85)]
									group-last:text-[#fce1fc] group-last:drop-shadow-[0_0_10px_rgba(201,73,151,0.75)]"
								>
									{Assets[i].logo}
									{Assets[i].title}
								</h3>
								<div className="grid -mb-[6.2%] lg:-mb-10">
									{CommentBg}
									<div className="col-span-full row-span-full text-sm md:text-base lg:text-lg p-[7.5vw] md:p-10 lg:p-14">
										<p className="overflow-y-auto max-h-[61vw] md:max-h-[30vw] lg:max-h-[217px]">
											{Assets[i].introduction}
										</p>
									</div>
								</div>
								<CtaButton to={Links.Characters}>
									{t("button.more_character")}
								</CtaButton>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};
