import cx from "classnames";
import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import type SwiperClass from "swiper/types/swiper-class";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactComponent as ForwardIcon } from "icons/forward.svg";
import { SectionHeading } from "components/SectionHeading";
import { WorldMarquee } from "components/WorldMarquee";

import "swiper/css";

export const Vision = (props: { vision: Queries.HomeQuery["vision"] }) => {
	const [swiper, setSwiper] = React.useState<SwiperClass | null>(null);
	const { t } = useTranslation();
	const handleNext = () => {
		swiper?.slideNext();
	};

	return (
		<div className="grid -mt-[20vw]">
			<StaticImage
				src="../images/vision-bg.jpg"
				alt=""
				layout="fullWidth"
				className="col-span-full row-span-full -z-20"
			/>
			<section className="col-span-full row-span-full flex flex-col pt-[25vw] pb-[40vw] md:pb-[30vw]">
				<SectionHeading id="world-overview">
					{t("nav.world_overview")}
				</SectionHeading>
				<div className="lg:max-w-screen-lg mx-auto mb-[10vw] grid">
					<div className="px-1 col-span-full row-span-full -z-10">
						<StaticImage
							src="../images/popup-bg.png"
							alt=""
							layout="fullWidth"
						/>
					</div>
					<div className="relative col-span-full row-span-full">
						<div className="w-full max-w-[100vw] lg:max-w-screen-lg text-sm md:text-lg lg:text-2xl px-10 md:px-20 lg:px-36 h-full flex items-center">
							<Swiper
								loop
								slidesPerView={1}
								spaceBetween={0}
								onSwiper={setSwiper}
							>
								{props.vision?.frontmatter?.list?.map((item, i) => (
									<SwiperSlide key={i} className="md:!h-auto">
										<div className="overflow-y-auto h-[42vw] w-full md:h-full space-y-4 lg:space-y-10 md:flex md:flex-col md:justify-center">
											{item?.content?.split("\n").map((line, j) => (
												<p key={j}>{line}</p>
											))}
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
						<Control
							onClick={handleNext}
							className="right-4 md:right-9 lg:right-10"
						/>
					</div>
				</div>
				<WorldMarquee images={props.vision?.frontmatter?.images ?? []} />
			</section>
		</div>
	);
};

const Control = (props: {
	onClick: () => void;
	inverse?: boolean;
	className?: string;
}) => {
	return (
		<button
			type="button"
			onClick={props.onClick}
			className={cx("absolute top-1/2 -translate-y-1/2", props.className)}
		>
			<ForwardIcon
				className={cx("w-[25px] md:w-[35px] h-[15px] md:h-[22px]", {
					"transform rotate-180": props.inverse,
				})}
			/>
		</button>
	);
};
