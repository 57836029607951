import { Billboard } from "components/Billboard";
import { CharacterSection } from "components/CharacterSection";
import { FeatureSection } from "components/FeatureSection";
import { Hero } from "components/Hero";
import { NewsSection } from "components/NewsSection";
import { Vision } from "components/Vision";
import { graphql, PageProps } from "gatsby";
import { Trans } from "gatsby-plugin-react-i18next";
import React, { Fragment } from "react";

export default function Home({ data }: PageProps<Queries.HomeQuery>) {
	return (
		<Fragment>
			<Billboard />
			<Hero id="pre-register" variant={1} className="-my-8 md:-my-6 2xl:-my-24">
				<Trans i18nKey="heading.start" />
			</Hero>
			<NewsSection items={data.news.edges} />
			<Hero id="characters" variant={2}>
				<Trans i18nKey="heading.character" />
			</Hero>
			<CharacterSection />
			<Hero id="feature" variant={3}>
				<Trans i18nKey="heading.feature" />
			</Hero>
			<FeatureSection features={data.features} weapons={data.weapons} />
			<Vision vision={data.vision} />
		</Fragment>
	);
}

export const query = graphql`
	fragment Translation on Locale {
		ns
		data
		language
	}
	query Home($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					...Translation
				}
			}
		}
		news: allMarkdownRemark(
			filter: {
				frontmatter: { type: { eq: "announcement" } }
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 4
		) {
			edges {
				node {
					...NewsFragment
				}
			}
		}
		features: allMarkdownRemark(
			filter: {
				frontmatter: { type: { eq: "feature" } }
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: ASC }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						introduction
						image {
							childImageSharp {
								gatsbyImageData(
									sizes: "(max-width: 600px) 100vw, 365px"
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
		vision: markdownRemark(
			frontmatter: { type: { eq: "vision" } }
			fields: { language: { eq: $language } }
		) {
			frontmatter {
				list {
					content
				}
				images {
					image {
						childImageSharp {
							id
							gatsbyImageData(width: 624, placeholder: BLURRED)
						}
					}
				}
			}
		}
		weapons: allMarkdownRemark(
			filter: {
				frontmatter: { type: { eq: "weapon" } }
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: ASC }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						introduction
						general
						icon {
							publicURL
						}
						image {
							childImageSharp {
								gatsbyImageData(width: 340, placeholder: BLURRED)
							}
						}
					}
				}
			}
		}
	}
`;

export { Head } from "components/GCSRewriteURL";
